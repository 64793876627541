.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  max-height: 100vh;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    height: 100%;
    padding: 30px 15px;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    padding: 20px 25px;
  }
}

.logo {
  position: absolute;
  top: 20px;
  left: 25px;
  display: flex;

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    margin-right: auto;
  }

  @media screen and (min-width: 768px) {
    max-height: 40px;
    height: 100%;

    img {
      object-fit: contain;
    }
  }
}

.closeButton {
  @media screen and (min-width: 279px) and (max-width: 1279px) {
    top: 30px;
    right: 5px;
  }

  @media screen and (min-width: 768px) {
    top: 20px;
    right: 20px;
  }
}

.input {
  display: flex;
  width: 100%;
  height: 38px;
  margin-right: 20px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--black);
  border: none;
  outline: none;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 16px;
  }

  &Container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    flex: 1;
    border: 1px solid var(--gray-text);
    transition: var(--transition);

    @media screen and (min-width: 768px) {
      border-right: none;
    }

    &:focus-within,
    &:active,
    &:hover {
      border-color: var(--gray-secondary);
    }
  }
}

.searchIcon {
  flex-shrink: 0;
  margin-right: 10px;
  color: var(--gray-text);
}

.searchContainer {
  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: calc(100% - 35px);
    margin-bottom: 30px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
  }
}

.searchContainer,
.content {
  display: flex;

  @media screen and (min-width: 768px) {
    max-width: 610px;
    width: 100%;
  }
}

.content {
  flex-direction: column;
  margin-bottom: 25px;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: 100%;
  }
}

.suggestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  @media screen and (min-width: 768px) {
    max-width: 650px;
    width: 100%;
  }
}

.link {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-decoration-line: underline;
  color: var(--black);
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
}

.list {
  max-height: 370px;
  overflow-x: auto;
  padding-right: 10px;

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.showResultsButton {
  flex: 0;
  width: fit-content;
  white-space: nowrap;
  padding: 0 15px;

  svg {
    path {
      stroke: var(--white);
    }
  }
}

.category {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--black);
}

.subtitle {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--black);
}

.vehicle {
  display: flex;
  align-items: center;
  padding: 5px 0;
  background-color: transparent;
  transition: var(--transition);
  cursor: pointer;

  @media screen and (min-width: 768px) {
    &:hover {
      background-color: var(--gray-light);
    }
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &Image {
    display: flex;
    flex-shrink: 0;
    mix-blend-mode: darken;
    object-fit: contain;

    @media screen and (min-width: 279px) and (max-width: 767px) {
      height: 50px;
      width: 70px;
      margin-right: 10px;
    }

    @media screen and (min-width: 768px) {
      height: 60px;
      width: 80px;
      margin-right: 15px;
    }
  }

  &Container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &Info {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: 3px;
    }
  }

  &Title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-right: auto;
    color: var(--black);
  }

  &Text {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  &Title,
  &Price,
  &Type {
    font-weight: 500;
  }

  &Price {
    display: flex;
    flex-shrink: 0;
    margin-left: 10px;
    color: var(--black);
  }

  &Type {
    color: var(--primary);
  }

  &Dot {
    display: flex;
    width: 3px;
    height: 3px;
    background-color: var(--gray-secondary);
    border-radius: 50%;
    margin: 0 5px;
  }

  &Mileage {
    color: var(--black);
  }
}

.callForPrice {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--primary);
  transition: var(--transition);

  &:hover {
    color: var(--primary-dark);
  }
}
