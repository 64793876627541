.modal {
  max-width: 100%;
  width: 100%;
  align-self: flex-end;
  background-color: transparent !important;
  height: fit-content !important;
}

.container {
  display: flex;
  width: 100%;
  background-color: var(--gray-border);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    flex-direction: column;
    padding: 25px 15px;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    padding: 25px 15px;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: 1280px) {
    padding: 15px 50px;
  }
}

.actions {
  display: flex;
}

.button {
  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: calc(50% - 7.5px);
  }

  @media screen and (min-width: 768px) {
    width: 170px;
  }
}

.text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3125rem;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: 768px) {
    margin-right: 25px;
  }
}
