.label {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  cursor: pointer;
  color: var(--gray-dark);
  transition: var(--transition);

  &:hover {
    color: var(--black);

    .radioIcon {
      border-color: var(--black);
    }
  }

  &.checked {
    color: var(--black);

    .radioIcon {
      background-color: var(--black);
      border-color: var(--black);
    }
  }

  &.inversedColors {
    .radioIcon {
      border-color: var(--white);
    }

    &:not(.checked) {
      .radioIcon {
        background-color: var(--black);

        svg {
          path {
            stroke: var(--black);
          }
        }
      }
    }
  }
}

.radio {
  width: 0;
  height: 0;
  opacity: 0;
}

.radioIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: var(--white);
  border: 1.5px solid var(--gray-dark);
  border-radius: 50%;
  transition: var(--transition);

  svg {
    margin-top: 1px;
    color: var(--white);
  }
}

.text {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  margin-left: 15px;
  color: var(--black);
}
