.closeButton {
  @media screen and (max-width: 767px) {
    width: 60px !important;
    height: 60px !important;
    top: 0 !important;
    right: 0 !important;
  }

  &:hover {
    svg {
      path {
        stroke: var(--black);
      }
    }
  }

  &.abovePopup {
    @media screen and (min-width: 768px) {
      top: 0 !important;
      transform: translateY(-100%) !important;
    }

    svg {
      path {
        stroke: var(--white) !important;
      }
    }

    &:hover {
      svg {
        path {
          stroke: var(--white) !important;
        }
      }
    }
  }
}
