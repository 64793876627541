.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 25px;
  font-weight: bold;
  line-height: 1.6875rem;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.375rem;
  }
}

.input {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.step {
  position: absolute;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--primary);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 768px) {
    top: 5px;
    left: -80px;
  }
}

.submitButton {
  max-width: 100%;
}

.actions {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.goBack {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  background-color: transparent;
  border: none;
  outline: none;
  transition: var(--transition);

  svg {
    path {
      fill: var(--black);
    }
  }

  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: fit-content;
    margin-bottom: 20px;
    color: var(--black);
  }

  @media screen and (min-width: 768px) {
    margin-right: 60px;

    color: var(--primary);

    svg {
      margin-right: 5px;

      path {
        transition: var(--transition);
        fill: var(--primary);
      }
    }

    &:hover {
      color: var(--primary-dark);

      svg {
        path {
          fill: var(--primary-dark);
        }
      }
    }
  }
}
