.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  overflow: hidden;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 40px 15px;
  }

  @media screen and (min-width: 768px) {
    padding: 110px 80px 50px;
  }

  &:after {
    content: "";
    position: absolute;
    border-radius: 100%;
    background-color: #1c77ff;
    filter: blur(160px);
    -webkit-filter: blur(160px);

    @media screen and (min-width: 279px) and (max-width: 767px) {
      width: 396px;
      height: 606px;
      left: 281px;
      top: 295px;
    }

    @media screen and (min-width: 768px) {
      width: 390px;
      height: 544px;
      left: 525px;
      top: 268px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 560px;
  margin: 0 auto;
}

.title {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.6875rem;
  color: var(--white);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-top: 15vh;
  }
}

.text {
  margin-bottom: 15px;
  font-size: 2rem;
  line-height: 2.5rem;
  color: var(--white);
}

.address {
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: var(--white);
}

.actions {
  position: relative;
  display: flex;
  margin-top: auto;
  z-index: 1;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    justify-content: flex-end;
  }
}

.proceed {
  @media screen and (min-width: 768px) {
    width: 265px;
  }
}

.skip {
  padding: 0 50px;
  color: var(--white);
  background-color: transparent;

  &:hover {
    color: var(--primary);
  }

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: 10px;
    border: 1px solid var(--white);

    &:hover {
      background-color: var(--white);
    }
  }
}
